<template>
    <div class="dashboard">
        <div v-for="kid of value" :key="kid.id">
            <dashboard-column 
                :kid="kid"
                @deleteEntry="$emit('deleteEntry', $event)"
                @postEntry="$emit('postEntry', $event)">
            </dashboard-column>
        </div>
    </div>
</template>

<script>
import DashboardColumn from './DashboardColumn.vue';

export default {
    name: "Dashboard",
    components: {
        DashboardColumn,
    },
    props: {
        value: {
            type: Array,
            required: true,
        },
    },
}
</script>

<style>
</style>
