<template>
    <div class="kid">
        <div class="summary" v-bind:style="'background-color: ' + kid.color">
            <h2>{{ kid.name }}</h2>

            <div class="amount balance">
                <p class="title">Current Balance</p>
                <p class="value">{{ kid.balance }}</p>
            </div>

            <div class="amount to-savings subaccount">
                <p class="title">
                    <button :class="['add', 'iconic', adding?'hidden':'shown']" @click="startAddSavings">
                        <font-awesome-icon icon="comment-dollar"></font-awesome-icon>
                    </button>
                    To Savings
                    <button :class="['sub', 'iconic', adding?'hidden':'shown']" @click="startDepSavings">
                        <font-awesome-icon icon="hand-holding-usd"></font-awesome-icon>
                    </button>
                </p>
                <p class="value">{{ kid.toSavings }}</p>
            </div>

            <div class="amount to-tithe subaccount">
                <p class="title">
                    <button :class="['add', 'iconic', adding?'hidden':'shown']" @click="startAddTithe">
                        <font-awesome-icon icon="comment-dollar"></font-awesome-icon>
                    </button>
                    To Tithe
                    <button :class="['sub', 'iconic', adding?'hidden':'shown']" @click="startDepTithe">
                        <font-awesome-icon icon="hand-holding-usd"></font-awesome-icon>
                    </button>
                </p>
                <p class="value">{{ kid.toTithe }}</p>
            </div>
            
            <div class="clear"></div>
        </div>

        <div class="ledger-group">
            <simple-ledger 
                v-model="kid" 
                @startEntry="startAddEntry($event)"
                @deleteEntry="$emit('deleteEntry', $event)">
            </simple-ledger>

            <add-entry-dialog
                v-model="addData" :adding="adding"
                :settings="addSettings"
                @postEntry="completePost"
                @cancelEntry="cancelPost">
            </add-entry-dialog>
        </div>
    </div>
</template>

<script>
import SimpleLedger from './SimpleLedger.vue'
import AddEntryDialog from './AddEntryDialog.vue'

export default {
    name: "DashboardColumn",
    components: {
        SimpleLedger,
        AddEntryDialog,
    },
    props: {
        kid: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            addSettings: {
                opdesc: "",
                via: "",
                account: "",
            },
            addData: {
                description: "",
                amount: 0,
            },
            adding: false,
        }
    },
    methods: {
        clearAddData() {
            this.addData = {
                description: "",
                amount: 0,
            }
        },
        startAddEntry(entry) {
            if (!entry.via) entry.via = ""
            this.addSettings = {
                opdesc: entry.opdesc,
                via: entry.via,
                account: entry.account,
            }
            this.clearAddData()
            this.adding = true
        },
        startAddTithe() {
            this.startAddEntry({
                opdesc: "Commitment",
                account: "AP:Church/$K",

            })
        },
        startDepTithe() {
            this.startAddEntry({
                opdesc: "Tithe to",
                via: "AP:Church/$K",
                account: "Church/$K",
            })
        },
        startAddSavings() {
            this.startAddEntry({
                opdesc: "For Saving",
                account: "AP:Savings/$K",
            })
        },
        startDepSavings() {
            this.startAddEntry({
                opdesc: "To Bank",
                via: "AP:Savings/$K",
                account: "Savings/$K",
            })
        },
        completePost() {
            var entry = {
                id: this.kid.id,
                description: this.addData.description,
                amount: this.addData.amount,
                via: this.addSettings.via,
                account: this.addSettings.account,
            }

            this.$emit("postEntry", entry)

            this.adding = false
        },
        cancelPost() {
            this.adding = false
        },
    },
}
</script>

<style>
.kid {
    float: left;
    width: 33%;
}

.kid h2 {
    font-size: 3em;
}

.kid .amount p {
    margin: 0;
}

.kid .amount .value {
    font-size: 2em;
    font-weight: bold;
}

.kid .amount .value:before {
    content: "$";
}

.kid .summary {
    border: 2px solid black;
    border-radius: 1em;
    box-shadow: 0 5px 5px rgba(0, 0, 0, .5);
    width: 80%;
    margin: 1em auto;
    padding: 0 0;
}

.kid .subaccount {
    padding-top: 2em;
    padding-bottom: 2em;
}

@media screen {
    @media (max-width: 1599px) {
        .kid  .subaccount {
            font-size: 0.7em;
        }
    }

    @media (max-width: 1199px) {
        .kid  .subaccount {
            font-size: 0.5em;
        }

        .ledger-group {
            font-size: 0.7em;
        }
    }

    @media (max-width: 799px) {
        .kid h2 {
            font-size: 2em;
        }

        .kid div.subaccount {
            float: none;
            padding-top: 2em;
            padding-left: 2em;
            padding-right: 2em;
        }

        .ledger-group {
            font-size: 0.5em;
        }
    }

    .kid .to-tithe {
        float: right;
        padding-right: 2em;
    }

    .kid .to-savings {
        float: left;
        padding-left: 2em;
    }
}


.ledger-group {
    position: relative;
    transform-style: preserve-3d;
    perspective: 1000px;
}

.clear {
    clear: both;
}

button.hidden {
    visibility: hidden;
}

button.shown {
    visibility: visible;
}
</style>
