<template>
    <div id="app">
        <dashboard 
            v-if="logged"
            v-model="kids" 
            @postEntry="postLine"
            @deleteEntry="deleteLine">
        </dashboard>
        <login v-if="!logged" @login="login"></login>
        <vue-confirm-dialog></vue-confirm-dialog>
    </div>
    <!--
        <div>{{ text }}</div>
    -->
</template>

<script>
import axios from 'axios'

import Dashboard from './components/Dashboard.vue'
import Login from './components/Login.vue'

const hc = axios.create()

export default {
    name: 'KidBank',
    components: {
        Dashboard,
        Login,
    },
    data() {
        return {
            errors: [],
            text: "nope",
            kids: [],
            authToken: "",
        }
    },
    computed: {
        logged() {
            return this.authToken !== ""
        },
        renewToken() {
            return this.isAppIdle ? "0" : "1"
        },
    },
    watch: {
        logged: function(newLogged, oldLogged) {
            if (newLogged && !oldLogged) {
                this.updateKids()
            }
            else {
                this.forgetKids()
            }
        },
    },
    created() {
        // hc.get("/api/v0/").then(response => {
        //     this.text = response.data
        // }).catch(e => {
        //     this.errors.push(e)
        // })

        window.setInterval(() => {
            if (this.logged) {
                this.apiRequest({
                    method: "GET", 
                    url: "/ping?renew=" + this.renewToken,
                }).catch(() => {})
            }
        }, 5000)
    },
    methods: {
        apiRequest(config) {
            config.headers = {
                "Authorization": "Bearer " + this.authToken,
            }
            config.url = "/api/v0" + config.url

            return hc.request(config).then(response => {
                if (response.data.success === "success") {
                    this.authToken = response.data.token
                }
                return response
            }).catch(e => {
                if (e.response.status == 401) {
                    this.authToken = ""
                }
                else {
                    throw e
                }
            })
        },
        updateKids() {
            this.apiRequest({
                method: "GET",
                url: "/kids",
            }).then(response => {
                if (response.data.status === "success") {
                    this.kids = response.data.kids
                }
                else {
                    this.errors.push(response.data.message)
                }
            }).catch(e => {
                this.errors.push(e.response.data.message)
            })
        },
        forgetKids() {
            this.kids = []
        },
        postLine(entry) {
            this.apiRequest({
                method: "POST",
                url: "/entries",
                data: entry,
            }).then(response => {
                if (response.data.status === "success") {
                    this.updateKids()
                }
                else {
                    this.errors.push(response.data.message)
                }
            }).catch(e => {
                this.errors.push(e.response.data.message)
            })
        },
        deleteLine(line) {
            this.apiRequest({
                method: "DELETE",
                url: "/entries/" + line.id,
            }).then(response => {
                if (response.data.status === "success") {
                    this.updateKids()
                }
                else {
                    this.errors.push(response.data.message)
                }
            }).catch(e => {
                this.errors.push(e.response.data.message)
            })
        },
        login(user) {
            hc.request({
                method: "POST",
                url: "/api/v0/auth", 
                data: user,
            }).then(response => {
                if (response.data.status === "success") {
                    this.authToken = response.data.token
                }
                else {
                    this.authToken = ""
                    this.errors.push(response.data.message)
                }
            }).catch(e => {
                this.errors.push(e.response.data.message);
            })
        },
    },
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

button.regular {
	background:linear-gradient(to bottom, #77b55a 5%, #72b352 100%);
	background-color:#77b55a;
	border-radius:.5em;
	border:1px solid #4b8f29;
	display:inline-block;
	cursor:pointer;
	color:#ffffff;
	font-family:Arial;
	font-size:1.25em;
	font-weight:bold;
	padding:.5em 1em;
	text-decoration:none;
	text-shadow:0 .1em 0 #5b8a3c;
}

button.regular:hover {
	background:linear-gradient(to bottom, #72b352 5%, #77b55a 100%);
	background-color:#72b352;
}

button.regular:active {
	position:relative;
	top:2px;
    left:2px;
}

button.iconic {
    background: inherit;
    border: none;
    cursor: pointer;
}

.field {
    position: relative;
    margin: 1em 0;
    font-size: 1.25em;
}

.field label {
    position: absolute;
    width: 40%;
    left: 8%;
}

.field input {
    width: 40%;
    margin-left: 50%;
}

.buttongroup {
    margin: .8em;
}

.buttongroup button {
    margin: .2em;
}

.clickable {
    cursor: pointer
}
</style>
