import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import IdleVue from 'idle-vue'

import VueConfirmDialog from 'vue-confirm-dialog'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle, faCommentDollar, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTimesCircle, faCommentDollar, faHandHoldingUsd)

Vue.config.productionTip = false
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

Vue.use(Vuex)
const store = new Vuex.Store()

const eventsHub = new Vue()
Vue.use(IdleVue, {
    idleTime: 30000,
    eventEmitter: eventsHub,
    store: store,
})

new Vue({
  render: h => h(App),
}).$mount('#app')
