<template>
    <div class="ledger-column">
        <div class="ledger">
            <div class="buttongroup">
                <button class="regular add" @click="startAddingExpense()">Add Expense</button>
                <button class="regular add" @click="startAddingIncome()">Add Income</button>
            </div>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th class="clickable desc" @click="reverse = !reverse">Description</th>
                        <th class="amt">Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="post" v-for="line of orderedLines" :key="line.id" :class="lineClass(line)">
                        <td class="act">
                            <button class="delete iconic" @click="confirmDeletePost(line)">
                                <font-awesome-icon icon="times-circle"></font-awesome-icon>
                            </button>
                        </td>
                        <td class="desc" :title="line.effectiveTime + ' ' + line.createdByName">{{ line.description }}</td>
                        <td class="amt">{{ line.amount }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr :class="value.balance < 0 ? 'neg' : 'pos'">
                        <td></td>
                        <td></td>
                        <td class="amt">{{ value.balance }}</td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

export default {
    name: "SimpleLedger",
    data() {
        return {
            opdesc: "",
            op: "",
            adding: false,
            errors: [],
            reverse: false,
        }
    },
    props: {
        value: {
            type: Object,
            required: true,
        },
    },
    computed: {
        orderedLines() {
            if (this.reverse) {
                return this.value.lines.slice().reverse()
            }
            else {
                return this.value.lines
            }
        }
    },
    methods: {
        startAddingIncome() {
            this.$emit('startEntry', {
                opdesc: "Earned from",
                account: "Income:General",
            })
        },
        startAddingExpense() {
            this.$emit('startEntry', {
                opdesc: "Spent on",
                account: "Expense:General",
            })
        },
        confirmDeletePost(line) {
            Vue.$confirm({
                message: "Are you sure you want to delete " + line.description + " for $" + line.amount + "?",
                button: {
                    no: "Keep",
                    yes: "Delete",
                },
                callback: c => {
                    if (c) {
                        this.deletePost(line)
                    }
                },
            })
        },
        deletePost(line) {
            this.$emit("deleteEntry", line)
        },
        lineClass(line) {
            if (line.accountName == "AP:Savings") {
                return 'saved';
            }
            else if (line.accountName == "AP:Church") {
                return 'tithed';
            }
            else if (line.amount < 0) {
                return 'neg';
            }
            else {
                return 'pos';
            }
        },
    },
}
</script>

<style>
.ledger-column {
    margin-top: 4em;
}

.ledger table {
    width: 70%;
    margin: 0 auto;
    border-collapse: collapse;
}

.ledger table tfoot {
    font-weight: bold;
}

.add-line-popup {
    display: block;
    background: #cccccc;
    padding: 1em;
    position: absolute;
    top: 0%;
    left: 5%;
    width: 82%;
    border-radius: 1em;
    border: 1px solid #999;
    box-shadow: 0 5px 10px rgba(0,0,0,0.5);
}

.popup-enter-active {
    animation: .7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both running slide-in-bck-center;
}

.popup-leave-active {
    animation: .7s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s 1 normal both running slide-out-bck-center;
}

.desc {
    text-align: left;
}

.amt {
    text-align: right;
}

td.amt:before {
    content: "$ ";
    float: left;
}

.neg {
    color: red;
}

.saved {
    color: blue;
}

.tithed {
    color: green;
}

button.delete {
    color: red;
    cursor: pointer;
    visibility: hidden;
}

tr.post:hover button.delete {
    visibility: visible;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-9-9 23:20:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bck-center
 * ----------------------------------------
 */
@keyframes slide-in-bck-center {
  0% {
    transform: translateZ(600px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation slide-out-bck-center
 * ----------------------------------------
 */
@keyframes slide-out-bck-center {
  0% {
    transform: translateZ(0);
    opacity: 1;
  }
  100% {
    transform: translateZ(-1100px);
    opacity: 0;
  }
}
</style>
