<template>
    <transition name="popup">
        <div @keyup.esc="cancelPost" @keyup.enter="completePost" class="add-line-popup" v-if="adding">
            <div class="field">
                <label v-bind:for="'description-' + value.id">{{ settings.opdesc }}</label>
                <input ref="desc" v-bind:id="'description-' + value.id" v-model="value.description">
            </div>
            <div class="field">
                <label v-bind:for="'amount-' + value.id">Amount</label>
                <input v-bind:id="'amount-' + value.id" v-model.number="value.amount">
            </div>
            <div class="buttongroup">
                <button class="regular" @click="completePost">Post</button>
                <button class="regular" @click="cancelPost">Cancel</button>
            </div>
        </div>
    </transition>
</template>

<script>
import Vue from 'vue'

export default {
    name: "AddEntryDialog",
    props: {
        settings: {
            type: Object,
            required: true,
        },
        value: {
            type: Object,
            required: true,
        },
        adding: {
            type: Boolean,
            requried: true,
        },
    },
    watch: {
        adding() {
            if (this.adding) {
                Vue.nextTick(() => { this.$refs.desc.focus() })
            }
        },
    },
    methods: {
        completePost() { this.$emit('postEntry') },
        cancelPost() { this.$emit('cancelEntry') },
    },
}
</script>
