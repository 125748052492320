<template>
    <div id="login" @keyup.enter="login">
        <div class="field">
            <label for="username">User</label>
            <input id="username" v-model="username">
        </div>

        <div class="field">
            <label for="password">Password</label>
            <input id="password" type="password" v-model="password">
        </div>

        <div class="buttongroup">
            <button class="regular" @click="login">Login</button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            username: "",
            password: "",
        }
    },
    methods: {
        login() {
            this.$emit("login", {
                user: this.username,
                pass: this.password,
            })
        },
    },
}
</script>

<style>
#login {
    margin: 20em auto 0 auto;
    width: 50%;
}
</style>
